<template>
    <svg id="markmap"></svg>
</template>

<script>
    import { defineComponent } from 'vue';
    import { Transformer } from 'markmap-lib';
    import * as markmap from 'markmap-view';

    export default defineComponent({
        name: "MindMapShow",
        props: ['content'],
        mounted() {
            if (this.content && this.content !== '') {
                this.preview(this.content);
            }
        },
        watch: {
            content(value, old) {
                if (!value || value === '') {
                    return
                }
                this.preview(value);
            }
        },
        methods: {
            preview(content) {
                const transformer = new Transformer();
                const { root, features } = transformer.transform(content);
                const { styles, scripts } = transformer.getUsedAssets(features);

                const { Markmap, loadCSS, loadJS } = markmap;
                if (styles) loadCSS(styles);
                if (scripts) loadJS(scripts, { getMarkmap: () => markmap });
                Markmap.create('#markmap', undefined, root);
            }
        }
    })
</script>

<style scoped lang="less">
    @max-preview-width: 666px;

    #markmap {
        width: 100%;
        max-width: @max-preview-width;
        height: calc(100vh - 198px);
    }
</style>